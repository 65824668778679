import React from 'react'
import { graphql, Link } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function IdeaComposition({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Challenge</h2>
        <p>
          Innovation projects hinge on <strong>key contributions</strong> ‒ the
          pivotal ideas crucial to the project's success. Key contributions
          connect needs with available solutions. They drastically reduce
          resource requirements and make projects feasible. In innovation
          projects, key contributions significantly outweigh other factors such
          as co-localization or resource availability.
        </p>{' '}
        <blockquote>Innovation lives from key contributions</blockquote>
        <p>
          Innovation projects require a project development platform that
          enhances the likelihood of receiving vital key contributions.
        </p>
        <p>
          While existing "idea markets" and "innovation management" are geared
          towards finding solutions for well-known problems, our challenge is at
          an earlier stage, where even the project structure itself benefits
          from improvement, and where contributions to a concept should require
          no friction.
        </p>
        <p>
          An online document with write access for everyone makes contributing
          easy. However, it's impossible to select only the key contributions.
          And it is limited: competing contributions cannot co-exist.
        </p>
        <blockquote>Get contributions easily, and select the best</blockquote>
        <p>
          If we want the best contributions, we need two things: To make it easy
          to receive contributions, and to allow negotiating proposed
          contributions before they are part of the project, so we can settle on
          the best.
        </p>
        <h2>Approach</h2>
        <p>
          We look at "easy but negotiable contributions" as a market mechanism,
          a question of demand and supply: Contributions can be solicited or
          unsolicited, can compete, be vetted, and the best are accepted to the
          project and iteratively improved.
        </p>{' '}
        <blockquote>A contribution market to develop your project</blockquote>
        <p>
          To achieve this, we first give every project participant full online
          write-access to the project concept. But everyone's modifications are
          automatically saved as separate copies, not affecting the version of
          anyone else. Then, participants can individually negotiate each
          other's changes and accept them as contributions.
        </p>
        <p>
          Now, a contribution can be anything from correcting a typo to
          re-writing the overall project structure to save resources.
        </p>
        <p>
          Therefore we build an algorithm that converts the modifications done
          by any participant into a list of intuitive standalone contributions
          that can be easily accepted, rejected, or modified. Our algorithm
          incorporates a number of approaches published in literature and
          extends and adapts concepts discussed there for our needs. Our
          algorithm is described in more detail at our{' '}
          <Link to="../treediff">Tree diff page</Link>.
        </p>
        <p>
          Finally, since participants "trade" contributions through individual
          preferences, we add a social layer to let participants coordinate
          choices as an organization
        </p>
        <h2>Result</h2>
        <p>
          An early version of the resulting application is currently being
          evaluated by a small number of businesses.
        </p>
        <blockquote>Give experts autonomy</blockquote>
        <p>
          Their decision makers are interested in the tool's capability to
          decrease manager moderation of decisions, from both a knowledge
          perspective (experts should know best) and a motivational perspective
          (give experts more autonomy).
        </p>
        <p>The web application is in private beta mode.</p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "ideaComposition" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ideacomp_feedback {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
